import { Controller } from "@hotwired/stimulus"
import { Jodit } from "jodit"
import Rails from "@rails/ujs"

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ["editor", "input"]

  editor = null

  connect() {
    console.log('connect jodit')
    console.log(this.editorTarget.id)

    this.editor = Jodit.make(`#${this.editorTarget.id}`, {
      minHeight: "300px",
      colorPickerDefaultTab: 'text',
      buttons: [
        'source', '|',
        'bold',
        'strikethrough',
        'underline',
        'italic', '|',
        'ul',
        'ol', '|',
        'outdent', 'indent', '|',
        'font',
        'fontsize',
        'brush',
        'paragraph', '|',
        'image',
        'table',
        'link', '|',
        'align', 'undo', 'redo', '|',
        'hr',
        'eraser'
      ],
      controls: {
        paragraph: {
          list: Jodit.atom({
            p: 'Pharagraph',
            h2: 'Heading 1',
            h3: 'Heading 2',
            h4: 'Heading 3',
            h5: 'Heading 4',
            blockquote: 'Quote',
            pre: 'Source code'
          })
        }
      },
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false
    })
  }

  updateEditor(event) {
    console.log('huh')
    let input = ''
    if (this.inputTarget.tagName === 'SELECT') {
      input = this.inputTarget.selectedOptions[0].value
    } else {
      input = this.inputTarget.value
    }
    const id = this.inputTarget.dataset.id
    const url = this.inputTarget.dataset.url

    console.log('huh')

    console.log(encodeURIComponent(this.editor.value))
    console.log('huh')

    event.preventDefault()

    Rails.ajax({
      url: url,
      type: 'get',
      data: "content=" + encodeURIComponent(this.editor.value) + "&" + id + "=" + input,
      dataType: 'script',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      beforeSend(xhr, options) {
        console.log('update editor')
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        return true
      },
      success: (partial) => {
        console.log('update success')
        this.editor.value = partial
      },
      error: (e) => {
        console.log('error updating')
      }
    })
  }

  disconnect() {
    this.removeEvent()
  }
}
