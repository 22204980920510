import { Controller } from "@hotwired/stimulus"
// import { useClickOutside, useIntersection, useTransition } from 'stimulus-use'
import { useTransition } from 'stimulus-use'
// import { IntersectionController } from 'stimulus-use'
// import { useTransition } from 'stimulus-use/dist/use-transition'

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ["menu", "input", "list"]

  eventCreated = false

  connect() {
    console.log('connect1')
    // useIntersection(this)
    // useClickOutside(this)
    this.menuTarget.classList.add('hidden')
    // this.element.textContent = "Hello World from Stimulus1! Dropdown"

    useTransition(this, { element: this.menuTarget })
  }

  search() {
    var input, filter, ul, li, a, i, txtValue
    input = this.inputTarget
    filter = input.value.toUpperCase()
    ul = this.listTarget
    li = ul.getElementsByTagName("li")

    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0]

      // przypadek wyszukiwania userów - napisy ostatnio wybierani/zespół/wszyscy nie są linkami i wywalały funkcję
      if (a !== undefined) {
        txtValue = a.textContent || a.innerText
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = ""
        } else {
          li[i].style.display = "none"
        }
      }
    }
  }

  toggle() {
    console.log('toggle1')
    this.toggleTransition()
    if (this.hasNavigationTarget) {
      this.navigationTarget.classList.remove('hidden')
    }
    if (this.hasSubContainerTarget) {
      this.subContainerTarget.classList.add('shadow-md')
    }
    // https://discuss.hotwired.dev/t/add-and-remove-eventlisteners/710
    if (this.eventCreated === false) {
      const fn = this.outsideHide.bind(this)
      this.eventCreated = fn
      console.log('eventCreated1')
      if (this.hasInputTarget) { this.inputTarget.focus() }
      document.addEventListener("click", fn)
    } else {
      this.removeEvent()
    }
  }

  hide(event) {
    // console.log('hide1', this.element, event.target)
    console.log('hide1', event)
    this.leave()
    this.removeEvent()
  }

  outsideHide(event) {
    console.log('outsideHide1', event)
    // this.hide(event)
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains('hidden')) {
      this.leave()
      this.removeEvent()
    }
  }

  removeEvent() {
    if (this.eventCreated !== false) {
      if (this.hasNavigationTarget) {
        this.navigationTarget.classList.add('hidden')
      }
      if (this.hasSubContainerTarget) {
        this.subContainerTarget.classList.remove('shadow-md')
      }
      console.log('removeEvent1')
      document.removeEventListener("click", this.eventCreated)
      this.eventCreated = false
    }
  }

  fillDropdown(event) {
    // select_id powinien miec format "new_ID_SELECTA_Z_FORMULARZA", wtedy tym dropdownem mozemy wypelnic wlasciwy select w formularzu
    let selectId = event.params.select
    const headerDiv = document.getElementById(selectId + '_name')
    const headerLink = headerDiv.querySelector('a')
    const modelUrl = event.params.url
    let chosenId = parseInt(event.params.id)
    chosenId = !Number.isInteger(chosenId) ? '' : chosenId
    selectId = selectId.split('new_')[1]
    const selectElement = document.getElementById(selectId)

    if (headerLink !== null) {
      headerLink.href = `${modelUrl}/${chosenId}`
      headerLink.text = event.path[0].text.trim()
    } else {
      // przypadek gdy wartością początkową było 'none'
      const newLink = document.createElement('a')
      newLink.setAttribute('href', `${modelUrl}/${chosenId}`)
      newLink.text = event.path[0].text.trim()
      headerDiv.innerHTML = ''
      headerDiv.appendChild(newLink)
    }
    selectElement.value = chosenId
    this.toggle()
  }

  copyLink(event) {
    const copyText = event.params.link
    const id = event.params.id

    console.log(event.params)
    navigator.clipboard.writeText(copyText)

    if (id) {
      const index = id.lastIndexOf('_')
      const prefix = id.slice(0, index)
      const copiedId = prefix + "_copied"
      const button = document.getElementById(id)
      const buttonCopied = document.getElementById(copiedId)

      button.classList.add('hidden')
      buttonCopied.classList.remove('hidden')

      setTimeout(() => {
        button.classList.remove('hidden')
        buttonCopied.classList.add('hidden')
      }, 1000)
    }
  }

  disconnect() {
    this.removeEvent()
  }
}
