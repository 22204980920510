import { useTransition } from 'stimulus-use'
import MultiselectController from "./multiselect_controller"
import Rails from "@rails/ujs"

export default class extends MultiselectController {
  // inputElement: elementy dodane w inpucie(domyslnie ukryty),
  // listElement: elementy widoczne po rozwinieciu dropdownu,
  // inputElement[i].id === listElement[i].params.id
  // targets z prefixem own to elementy tworzone na podstawie inputu od usera, dzieki temu mozemy wysylac na maile spoza listy kontaktow
  static targets = ["placeholder", "list", "input", "listElement", "inputElement", "spinner",
    "notFound", "container", "ownInputElement", "ownListElement", "ownListElementTick",
    "ownListElementText", "ownListElementX", "ownInputElementText", "ownListElementTemplate", "ownInputElementTemplate"]

  eventCreated = false
  hiddenInputPrefix = this.getPrefix(this.menuTarget.id)
  hiddenInput = document.getElementById(this.hiddenInputPrefix)
  ownInputContext = 'own0'
  templateRefrence = 'own0'

  connect() {
    console.log('multiselect search')

    this.notFoundTarget.classList.add('hidden')
    this.spinnerTarget.classList.add('hidden')
    this.menuTarget.classList.add('hidden')

    const values = this.hiddenInput.value.split(',').filter(n => n)

    console.log('values', values)
    this.startSelect(values)

    this.createNewOwnElement('initial')

    useTransition(this, { element: this.menuTarget })
  }

  select(event) {
    const index = event.params.id.lastIndexOf('_')
    const id = event.params.id.slice(index + 1)
    const prefix = this.getPrefix(event.params.id)
    const show = document.getElementById(prefix + '_show_' + id)
    const email = show.dataset.email
    const newIncrementedId = this.incrementId(event.params.id)
    const suffix = this.getSuffix(event.params.id)
    this.inputTarget.focus()
    this.inputTarget.value = ''

    console.log('id', event.params.id)
    if (suffix.includes('own') && !document.getElementById(newIncrementedId)) {
      this.createNewOwnElement(id)
    }

    this.selectHelper(prefix, id, email, show)
    this.filterDropdown()
  }

  startSelect(values) {
    const menuId = this.menuTarget.id
    const prefix = this.getPrefix(menuId)
    const nodes = this.inputElementTargets
    const ids = {}

    nodes.forEach(node => {
      const email = node.dataset.email
      const index = node.id.lastIndexOf('_')
      const id = node.id.slice(index + 1)
      if (values.indexOf(email) !== -1) {
        ids[email] = id
      }
    })

    if (ids !== undefined) {
      this.createNewOwnElement(this.ownInputContext)
    }

    values.forEach(email => {
      if (ids[email]) {
        const valueShow = document.getElementById(prefix + '_show_' + ids[email])
        this.selectHelper(prefix, ids[email], email, valueShow)
      } else {
        // TODO ogarniecie wykrywania ktore elementy trzeba zaznaczyc
        // const elements = Array.from(document.querySelectorAll(`[data-email='${email}']`))
        // var elementCount = false
        // // debugger
        // elements.filter(function(element) {
        //   if (element.dataset.email === email && element.id.includes('_own')) {
        //     elementCount = true
        //   }
        // })

        // if (!elementCount) {
        console.log('email', email)
        this.inputTarget.value = email
        const e = {
          params: {
            id: `${this.hiddenInputPrefix}_${this.ownInputContext}`
          },
          target: {
            value: email
          },
          key: 'Enter'
        }
        this.fillOwnInput(e)
        this.selectEnter(e)
        // }
      }
    })
  }

  selectHelper(prefix, id, email, show) {
    const tick = document.getElementById(prefix + '_tick_' + id)
    const x = document.getElementById(prefix + '_x_' + id)

    // pole formularza
    const field = document.getElementById(prefix)
    let values = field.value.split(',')

    if (tick.classList.contains('hidden')) {
      tick.classList.remove('hidden')
      x.classList.remove('hidden')
      show.classList.remove('hidden')
      show.classList.add('flex')
      // zabezpieczenie by nie dodawać kilka razy tego samego elementu
      if (values.indexOf(email) === -1) {
        values.push(email)
      }
    } else {
      tick.classList.add('hidden')
      x.classList.add('hidden')
      show.classList.add('hidden')
      show.classList.remove('flex')
      values = values.filter(e => e !== email)
    }

    field.value = values.join(',')
    field.innerText = values.filter(n => n)
  }

  updateSearch(event) {
    if (this.inputTarget.value.length > 0) {
      this.selectEnter(event)
    }

    this.toggleInput()
    this.notFoundTarget.classList.add('hidden')
    this.spinnerTarget.classList.remove('hidden')
    this.filterDropdown(event)
    this.fillOwnInput(event)
  }

  filterDropdown(event = undefined) {
    const field = this.hiddenInput
    const input = this.inputTarget.value
    const url = field.dataset.url
    const prefix = field.id
    const ids = field.value.split(',')
    const idsToTick = []
    ids.shift()

    console.log('targets', this.ownInputElementTargets)

    ids.forEach((id) => {
      const inputElement = this.inputElementTargets.find(target => id === target.dataset.email)
      const ownElement = this.ownInputElementTargets.find(target => id === target.dataset.email)

      // TODO ogarnac chowanie duplikatow
      // const duplicatesFound = this.ownInputElementTargets.some(target => target.dataset.email === ownElement.dataset.email &&
      //   target.id !== ownElement.id)
      // console.log('duplicate', duplicatesFound)

      if (inputElement !== undefined) {
        idsToTick.push(this.getSuffix(inputElement.id))
      }

      if (ownElement && inputElement && ownElement.dataset.email !== inputElement.dataset.email) {
        idsToTick.push(this.getSuffix(ownElement.id))
      }
    })

    console.log('idstotick', idsToTick)
    console.log('prefix', prefix)
    if (event !== undefined) {
      event.preventDefault()
    }

    Rails.ajax({
      url: url,
      type: 'get',
      data: "id=" + prefix + "&q=" + encodeURIComponent(input),
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        return true
      },
      success: (partial) => {
        this.spinnerTarget.classList.add('hidden')

        console.log("values", ids)
        // this.startSelect(ids)
        idsToTick.forEach(id => this.tickOption(prefix, id))
      },
      error: (e) => {
        this.spinnerTarget.classList.add('hidden')
        this.notFoundTarget.classList.remove('hidden')
        console.log('update failed')
      }
    })
  }

  fillOwnInput(event) {
    const text = event.target.value
    const contactElement = this.inputElementTargets.find(target => target.dataset.email === text)
    const targetedInputElement = this.ownInputElementTargets.find(target => this.getSuffix(target.id) === this.ownInputContext)
    const targetedListText = this.ownListElementTextTargets.find(target => this.getSuffix(target.id) === this.ownInputContext)
    const targetedListElement = this.ownListElementTargets.find(target => this.getSuffix(target.id) === this.ownInputContext)
    const targetedInputText = this.ownInputElementTextTargets.find(target => this.getSuffix(target.id) === this.ownInputContext)
    targetedInputElement.dataset.email = text
    targetedListText.innerText = text
    targetedInputText.innerText = text

    console.log('contact element', contactElement)

    if (contactElement || text.length < 1) {
      targetedListElement.classList.add('hidden')
    } else {
      targetedListElement.classList.remove('hidden')
    }
  }

  createNewOwnElement(id) {
    // first element created
    const prefix = this.hiddenInputPrefix
    // const ownInputContext = this.ownInputContext
    const templateRefrence = this.templateRefrence
    const autocompleteContainer = document.querySelector(`#${prefix}-multisearch-autocomplete-container`)
    const inputsContainer = document.querySelector(`#${prefix}-multisearch-inputs-container`)
    const mainId = `${prefix}_${templateRefrence}`
    const tickId = `${prefix}_tick_${templateRefrence}`
    const optionId = `${prefix}_option_${templateRefrence}`
    const xId = `${prefix}_x_${templateRefrence}`
    const showId = `${prefix}_show_${templateRefrence}`
    const showOptionId = `${prefix}_show_option_${templateRefrence}`
    const showXId = `${prefix}_show_x_${templateRefrence}`

    if (id === 'initial') {
      this.createInitialOwnElement(autocompleteContainer, inputsContainer)
    } else {
      // const recentSuffix = this.getSuffix()
      // // return if the element is already created
      // if (document.getElementById(newMainId)) {
      //   return
      // }

      this.ownInputContext = this.incrementId(this.ownInputContext)
      const newMainId = this.replaceSuffix(mainId, this.ownInputContext)
      const newTickId = this.replaceSuffix(tickId, this.ownInputContext)
      const newOptionId = this.replaceSuffix(optionId, this.ownInputContext)
      const newXId = this.replaceSuffix(xId, this.ownInputContext)
      const newShowId = this.replaceSuffix(showId, this.ownInputContext)
      const newShowOptionId = this.replaceSuffix(showOptionId, this.ownInputContext)
      const newShowXId = this.replaceSuffix(showXId, this.ownInputContext)
      console.log('mainid', mainId)

      console.log('newMainId', newMainId)
      console.log(this.ownInputContext)

      // new list element
      const newListElement = this.ownListElementTemplateTarget.content.cloneNode(true)
      newListElement.getElementById(mainId).id = newMainId
      newListElement.getElementById(tickId).id = newTickId
      newListElement.getElementById(optionId).id = newOptionId
      newListElement.getElementById(xId).id = newXId
      newListElement.querySelector('div').dataset.multiselectSearchIdParam = newMainId
      autocompleteContainer.prepend(newListElement)

      // new input element
      const newInputElement = this.ownInputElementTemplateTarget.content.cloneNode(true)
      newInputElement.getElementById(showId).id = newShowId
      newInputElement.getElementById(showOptionId).id = newShowOptionId
      newInputElement.getElementById(showXId).id = newShowXId
      newInputElement.getElementById(newShowXId).dataset.multiselectSearchIdParam = newMainId
      inputsContainer.prepend(newInputElement)
    }
    // const clone = this.ownListElementTemplateTarget.content.cloneNode(true)
    // console.log(clone)
  }

  createInitialOwnElement(autocompleteContainer, inputsContainer) {
    const initialListElement = this.ownListElementTemplateTarget.content.cloneNode(true)
    const initialInputElement = this.ownInputElementTemplateTarget.content.cloneNode(true)
    autocompleteContainer.prepend(initialListElement)
    inputsContainer.prepend(initialInputElement)
  }

  incrementId(id) {
    const numbers = id.match(/[0-9]+$/)
    const newNumbers = parseInt(numbers) + 1
    const newId = id.replace(numbers.toString(), newNumbers.toString())
    return newId
  }

  replaceSuffix(element, newSuffix) {
    const suffix = this.getSuffix(element)
    const newElement = element.replace(suffix, newSuffix)
    return newElement
  }

  tickOption(prefix, id) {
    const tick = document.getElementById(prefix + '_tick_' + id)
    const x = document.getElementById(prefix + '_x_' + id)

    if (tick && tick.classList.contains('hidden')) {
      tick.classList.remove('hidden')
      x.classList.remove('hidden')
    }
  }

  // jeśli wciśnięto enter to dodaj to co w inputcie
  selectEnter(event) {
    if (event.key === 'Enter') {
      console.log(event)
      const params = {
        id: `${this.hiddenInputPrefix}_${this.ownInputContext}`
      }
      event.params = params
      this.select(event)
    }
  }
}
